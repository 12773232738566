<template>
  <v-container>
    <loading-data
      v-if="progressBar"
      :feedbackText="$t('global.loading-data')"
    ></loading-data>
    <template v-else>
      <h1 class="black--text">
        {{
          $t(
            isNewSuggestion
              ? "general.add-suggestion"
              : "general.edit-suggestion"
          )
        }}
      </h1>
      <h3 class="my-3">* {{ $t("general.add-suggestion-subtitle") }}</h3>
      <span class="error--text mb-1" v-if="suggestionText.length < 40"
        >** {{ $t("general.enter-at-least-40-chars") }}</span
      >
      <v-textarea
        :label="$t('general.suggestion-description')"
        outlined
        v-model="suggestionText"
      />
      <v-row>
        <v-spacer />
        <custom-button
          :text="$t(isNewSuggestion ? 'btn.add' : 'btn.edit')"
          icon="add"
          :disabled="suggestionText.length < 40"
          @click="addOrUpdateSuggestion"
        />
        <custom-button
          type="warning"
          :text="$t('btn.cancel')"
          icon="close"
          @click="goBack"
        />
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { db } from "@/main";
import CustomButton from "@/components/CustomButton.vue";
import { isEqual } from "lodash";
import LoadingData from "@/components/utilities/LoadingData.vue";
export default {
  components: { CustomButton, LoadingData },
  data() {
    return {
      progressBar: false,
      suggestionId: this.$route.params.suggestionId,
      suggestionText: "",
    };
  },
  computed: {
    isNewSuggestion() {
      return isEqual(this.suggestionId, "new");
    },
  },
  created() {
    this.getInitialText();
  },
  methods: {
    async getInitialText() {
      this.progressBar = true;
      if (!this.isNewSuggestion) {
        let suggestionResponse = await db
          .collection("workersSuggestions")
          .doc(this.suggestionId)
          .get();
        if (suggestionResponse.exists) {
          let data = suggestionResponse.data();
          this.suggestionText = data.suggestionText;
        } else {
          this.goBack();
        }
      }
      this.progressBar = false;
    },
    goBack() {
      this.$router.push({
        name: `workerSuggestionRegister`,
      });
    },
    async addOrUpdateSuggestion() {
      this.progressBar = true;
      if (this.isNewSuggestion) {
        let object = {
          suggestionText: this.suggestionText,
          updatedBy: this.$root.currentUserData.displayName,
          updatedAt: new Date(),
          dateKey: new Date().toISOString().substring(0, 10),
          monthKey: new Date().toISOString().substring(0, 7),
          userId: this.$root.currentUserData.userId,
          userName: this.$root.currentUserData.displayName,
          userServices: this.$root.currentUserData.aiderServices,
          status: "submitted",
        };
        await db.collection("workersSuggestions").add(object);
      } else {
        let object = {
          suggestionText: this.suggestionText,
          updatedBy: this.$root.currentUserData.displayName,
          updatedAt: new Date(),
        };
        await db
          .collection("workersSuggestions")
          .doc(this.suggestionId)
          .update(object);
      }
      this.progressBar = false;
      this.goBack();
    },
  },
};
</script>

<style>
</style>